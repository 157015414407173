@font-face {
    font-family: 'FS Elliot Pro';
    src: url("FS_Elliot_ProRegular.otf") format("opentype");
    font-style: normal;
}

@font-face {
    font-family: 'FS Elliot Pro';
    src: url("FS_Elliot_ProBold.otf") format("opentype");
    font-style: normal;
    font-weight: bold;
}